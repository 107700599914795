.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(1.2rem, calc(10px + 2vmin), 1.5rem);
  color: white;
  padding-bottom: 4em;
}

a {
  color: #61dafb;
  text-decoration: none;
}

.seed {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;
  gap: 10px;
  padding: 10px 16px;
  margin: 1rem 0 0;
  max-width: 100%;
  box-sizing: border-box;
}

.seed > span {
  font-size: 1em;
  color: #fffa;
}

.seed > input {
  background: none;
  border: none;
  border-bottom: 2px solid #fff5;
  border-radius: 0;
  color: white;
  font-size: 1.5em;
  width: 200px;
  text-align: center;
  max-width: 100%;
}

button {
  cursor: pointer;
  font-size: 1em;
  background-color: #0004;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  color: #fffa;
  box-shadow: 0 0 24px -8px #0004;
  transition: box-shadow 0.2s;
}

button:hover {
  background-color: #0003;
}

button:active {
  box-shadow: 0 0 0 0 #0004;
}

.list {
  margin-top: 2em;
  width: 100%;
  max-width: 1300px;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(160px + 16px));
  grid-gap: 1rem;
  justify-content: space-evenly;
}

.list-element {
  width: calc(160px + 16px);
}

.list-element-name {
  font-size: 0.7em;
  color: #fffd;
}

.list-element:hover canvas {
  box-shadow: 0 0 24px -16px #000;
}

.title {
  text-align: left;
  align-self: center;
  width: calc(100% - 1em);
  max-width: 1240px;
  margin: 2em 1em 0;
  box-sizing: border-box;
}

canvas,
.preview {
  max-width: calc(100% - 16px);
  width: auto !important;
  height: auto !important;
  margin: 8px;
  border-radius: 4px;
  box-shadow: 0 0 24px -8px #000;
  transition: 0.1s ease-in-out box-shadow;
}
